import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div align="center">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFDElEQVQ4yy2TWVNUVxSF+y9oFBF6nufb89z07YFuhm5omrYRBRRpGpBBRhUU0BAURypGoymHiiYxmpRWJfqSKt9SKSt5yFMe8m++HDAPu865+5y9zlp77atQ6swo9RY0Jhtqk51AUiZZ6CJXqTE8OcnO7W3+/PAd/3x8w6vXjxlfWiY/MEy40IsUz6C1ulEZP9VqzE4UaqOVvdBbnZicXtLdJbLlKslihY6BE1y/tcW/f73l7z8E4E9POH1uHrlvgMroGPH2LgHoEoTMtO6RMttR6CyOfTCb5McTipIQ7E5OTZHtP064o8zshWU+fnjB7799y6uX99m5c5Xx+VlmVuYJyHmicgaLU0IrwOyeAAp/NIHcnieRzuD2BwjJ7UwuL9I1OESkUGJwvMHDe1d49fwm9x7e5Omzu1y6uka+f4BUZxF/OEKukCcUTyD5gygibfI+WCSZJBKLUKqUqQo5tTNnyPZWGJ1ssLy6yK3dbbZuf0GtXqd2enQ/313uoaevm+pAhVQmQzTZhsLp8dGWSuEJRkjIKUZGjtOYbjC9OMvsygInJiaYWFrk8vUttu5sMzY/x/TCHPXGafqqZdo7O/AEQsii1ukVksOSRNDnIxyLC/pRQskUJ08Ps33jCo+ff829pw959eYFv7x7yTcvHvHlo13ml88RiCWxur3CYSt2t4dcOkUuEUORFjLTkRBpuU1Qr+KLxLF6QsTSWc5MNLh19wY/vnzC+19/4Mmz+8wuL5AqdGO0u9FbbJisDiE1yfqJTjarWRSyR0L2ecm1Z2mMj2BzfXLM4PAQTecJJNJkukrURk7RWa7gDkb3JyImZ3F7/cTDIU4NVrg72c94Rpgiud2EAz5SgmGlXKSnp8hofZRCTy+57h4SmXa8gnUkld6X6fIFqdaq1IUpZWHKQmOYmVo3fREvFqMJRSoRpdTbRW2gTH9/kTFx4drOBhfXFplbmGFz6zIXL5/n3NIcm1dX2dhcYefaKjevrzE5NcqpSpGU5MIi5HsEOUVYryMTDnC81suZoX4WR4+xcXGK3ZurPNhd5/Xz27z/+QEf3j3h7fd32dlaYu3CWbbXxRQMVxks5RkbEmtfJ+WokOxqVeJVqXDptLjUKobcdk72FJiYHOGsiNXzU6yvzXDj80XubC+xeWlOMLzAxtQQ0yEPPrORckGmJNlJiHqFWQDGjAZiZhMBrZZev5POZIhsTqZfDG2jfpLl+Qb1+jBjIiZES8oV8Qd1p6n5nMRMejokBwlBSN3cgkLf0opSqaH5qBK/yUDe7ya490A4KIxKcKy3g55ckmJ7gv5SjqToeT4ZJuVzUwp7iFhNaNRa9Co1R48IQE2LCpPeiF6rx9x8lIBSRcBgIBwKIsfDDFa6KHbmWJka4KvNOtFggGw8RMJiImMyYmttQSNqDVodTYeOfGLoFLKjOh1xo5GkTUy+yUwu5qMkRiHslVCqBAPxaEyYV0yLqchEcIl7MY+QbDMjWcxYNRpaBSFFzO0SLgeJSU4CDguS047NaiUVEpKyUfwuOwcOHObAwcMEBUC+LUS3HMRptyK5HPhcNiS7BatRj99pQWEziI3HhVsk0xGJgEh6bEb8DmGS04xDNP3gZ000NTVTzESJeh2EXBZ84txm1O2DpEISXqcVs0GHQnm0BaNeK/qoxahRoVUp0auV+3uLTnyLHh0+1CTiCGqx16la/z9TYxKrTty3GrToNHumNPMfqavgzgewxjIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMAGE",
            "title": "IMAGE",
            "src": "/static/74f565c5e146b2d85ea4a5ae1003948b/da8b6/blog-unleashing-soundscapes.png",
            "srcSet": ["/static/74f565c5e146b2d85ea4a5ae1003948b/43fa5/blog-unleashing-soundscapes.png 250w", "/static/74f565c5e146b2d85ea4a5ae1003948b/c6e3d/blog-unleashing-soundscapes.png 500w", "/static/74f565c5e146b2d85ea4a5ae1003948b/da8b6/blog-unleashing-soundscapes.png 1000w", "/static/74f565c5e146b2d85ea4a5ae1003948b/8a610/blog-unleashing-soundscapes.png 1023w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "an-orchestra-of-digital-voices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#an-orchestra-of-digital-voices",
        "aria-label": "an orchestra of digital voices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`An Orchestra of Digital Voices`}</h3>
    <p>{`Imagine a symphony where every instrument has a unique, humanlike voice. And you are the
conductor! With our generative voice AI, that’s no longer a pipe dream but a resounding
reality. Boasting an ensemble of over 60 lifelike voices, our advanced system is a
veritable carnival of sound that’s ready to bring your ideas to life.`}</p>
    <p>{`But don’t let that number fool you - this is just the tip of the iceberg! Your journey
into the world of artificial intelligence doesn’t stop at predefined options. With the
power to customize and create, you are the architect of your sound experience.`}</p>
    <h3 {...{
      "id": "craft-the-voice-of-your-dreams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#craft-the-voice-of-your-dreams",
        "aria-label": "craft the voice of your dreams permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Craft the Voice of Your Dreams`}</h3>
    <p>{`Say goodbye to voice packs and standard text-to-speech. Welcome to a world where you
hold the reins to timbre and every emotional hue. Whether you need the voice of an
old wizard for your groundbreaking game or a soothing narrative tone for your latest
audiobook, the sky’s the limit with our voice generation tools.`}</p>
    <p>{`Every story is unique, and every character deserves a voice that fits. With our voice
generation tools, the unique voices of your characters will immerse players in a world
that’s as real as it is exciting.`}</p>
    <h3 {...{
      "id": "prompt-to-voice-when-ai-listens-to-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#prompt-to-voice-when-ai-listens-to-you",
        "aria-label": "prompt to voice when ai listens to you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Prompt-to-Voice: When AI Listens to You`}</h3>
    <p>{`Yet, our mission to revolutionize auditory experiences doesn’t end with mere voice
generation tools. Prompt-to-voice is ready to take your prompts (e.g. “An old man with
a gravelly voice and an American accent”) and transform them into captivating voices
that you can then use to voice your creations.`}</p>
    <p>{`Whether you’re in the gaming industry, the world of eLearning, or you’re an ambitious
creative with a vision, our prompt-to-voice technology is a tool of change, of
innovation, and of progress. A tool that listens, understands, and then speaks in a
voice that’s uniquely yours.`}</p>
    <p>{`Remember, the symphony is yours to conduct. So, why not make it extraordinary? Let’s
create a soundscape that’s as unique as you are, and let’s do it together. In the
world of sound, the future is now, and the future is ours to shape!`}</p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-unleashing-soundscapes-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-unleashing-soundscapes-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      